var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-mask", on: { click: _vm.onClose } }, [
    _c(
      "div",
      {
        ref: "wrapper",
        staticClass: "modal-wrapper",
        class: { "modal-wrapper--fullscreen": _vm.type === "fullscreen" },
        attrs: { tabindex: "0" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
          keydown: _vm.keyDown,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-container popup",
            class: {
              [`popup--${_vm.type}`]: _vm.type,
              [`popup--${_vm.name}`]: _vm.name,
            },
            on: {
              mousedown: _vm.onPopupMouseDown,
              mouseup: _vm.onPopupMouseUp,
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "modal-default-button popup__close",
                attrs: { type: "button", title: "Закрыть" },
                on: { click: _vm.close },
              },
              [_c("fa-icon", { attrs: { icon: "times", size: "lg" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-header popup__header" },
              [_vm._t("header")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body popup__body" },
              [_vm._t("body")],
              2
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }