var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.type === _vm.basketTypes.product
      ? _c("span", { staticClass: "badge badge-primary" }, [_vm._v("Товары")])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === _vm.basketTypes.master
      ? _c("span", { staticClass: "badge badge-info" }, [_vm._v("Услуги")])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === _vm.basketTypes.certificate
      ? _c("span", { staticClass: "badge badge-info" }, [_vm._v("Сертификат")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }