var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-item" },
    [
      _vm.item.items
        ? _c(
            "span",
            {
              staticClass: "menu-text heading",
              class: { active: _vm.active },
              style: _vm.indent,
              attrs: { title: _vm.item.title },
              on: {
                click: function ($event) {
                  return _vm.toggleItem()
                },
              },
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.item.name ? _vm.item.name : _vm.item.title) +
                  "\n          "
              ),
              _c("fa-icon", {
                staticClass: "float-right shevron",
                attrs: { icon: _vm.opened ? "angle-down" : "angle-right" },
              }),
            ],
            1
          )
        : _c(
            "a",
            {
              staticClass: "menu-text",
              class: { active: _vm.active, empty: _vm.item.route === "#" },
              style: _vm.indent,
              attrs: { href: _vm.item.route },
            },
            [_vm._v(_vm._s(_vm.item.title))]
          ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.item.items && _vm.opened
          ? _c(
              "div",
              { staticClass: "menu-items" },
              _vm._l(_vm.item.items, function (subitem, index) {
                return _c("menu-item", {
                  key: index,
                  attrs: { item: subitem, depth: _vm.depth + 1 },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }