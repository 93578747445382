var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "badge", class: _vm.statusClass(_vm.status.id) },
    [_vm._v(_vm._s(_vm.status.name || "N/A"))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }