import Vuex from 'vuex';
import Vue from 'vue';
import NetService from '../../scripts/services/net';

import ModalModule from './modules/modal';
import massSelection from './modules/mass-selection';

import products from './modules/products';
import returnProducts from './modules/return_products';
import brands from './modules/brands';
import organizers from './modules/organizers';
import publicEvents from './modules/public-events';
import places from './modules/places';
import speakers from './modules/speakers';
import types from './modules/types';
import serviceNotifications from './modules/service-notifications';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        title: '',
        layout: {},
        env: {},
        routes: {},
        loaderShow: false,
        user: null,
    },
    modules: {
        modal: ModalModule(),
        massSelection,
        products,
        returnProducts,
        brands,
        places,
        publicEvents,
        organizers,
        speakers,
        types,
        serviceNotifications,
    },
    mutations: {
        loaderShow(state, loaderShow) {
            state.loaderShow = loaderShow;
        },
        title(state, data) {
            state.title = data;
            document.title = data;
        },
        env(state, data) {
            state.env = data;
        },
        layout(state, data) {
            state.layout = data;
        },
        routes(state, routes) {
            state.routes = routes;
        },
        user(state, user) {
            state.user = user;
        },
    },
    getters: {
        getRoute: state => (name, params = {}) => {
            const r = state.routes[name];

            if (!r) {
                return '';
            }

            const {uri} = NetService.prepareUri(r, params);

            return `/${uri.replace(/^\//, '')}`;
        },
    },
});
