var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("fa-icon", {
        staticClass: "navbar-item navbar-icon",
        attrs: { icon: "comment-dots", size: "lg" },
        on: {
          click: function ($event) {
            _vm.notificationsOpened = !_vm.notificationsOpened
          },
        },
      }),
      _vm._v(" "),
      _vm.notificationsCount > 0
        ? _c(
            "span",
            {
              staticClass: "badge badge-pill badge-danger notification-badge",
              on: {
                click: function ($event) {
                  _vm.notificationsOpened = !_vm.notificationsOpened
                },
              },
            },
            [_vm._v(_vm._s(_vm.notificationsCount))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.notificationsOpened
        ? _c("div", { staticClass: "notifications shadow" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-link p-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.markNotifications()
                  },
                },
              },
              [_vm._v("Пометить все как прочитанные")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "notifications-wrapper overflow-auto" },
              _vm._l(_vm.notifications, function (notification) {
                return _c("div", { staticClass: "card mb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-body p-1",
                      class:
                        notification.status === 2 ? "notification-old" : "",
                    },
                    [
                      _c("h6", { staticClass: "card-title mb-0" }, [
                        _vm._v(_vm._s(notification.payload.title)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "small",
                        { staticClass: "card-subtitle mb-2 text-muted" },
                        [_vm._v(_vm._s(notification.created_at))]
                      ),
                      _vm._v(" "),
                      notification.payload.body
                        ? _c("p", {
                            staticClass: "card-text mt-2 mb-1",
                            domProps: {
                              innerHTML: _vm._s(notification.payload.body),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      notification.payload.url
                        ? _c(
                            "a",
                            {
                              staticClass: "card-link",
                              attrs: { href: notification.payload.url },
                            },
                            [_vm._v("Подробнее...")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }